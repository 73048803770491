import Head from 'next/head';

import { ConnectWallet } from '@/components/modules/home';
import { Center } from '@chakra-ui/react';

import { useAuth } from '@/hooks';

const HomePage = () => {
  //
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <>
        <Head>
          <title>Graviton | Connect Wallet</title>
        </Head>
        <Center p="120px 0">
          <ConnectWallet />
        </Center>
      </>
    );
  }
};

export default HomePage;
